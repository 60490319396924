:root{
  --primary-colour: #1c173b;
  --secondary-colour: #faf4d3;
  --text-colour: #af46a6;
  --background-colour: #242424;
  --default-font: "Consolas", 'Courier New', monospace;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--background-colour);
  font-family: var(--default-font);
  font-size: 20px;
  color: #393e41;
  padding: 0;
}

.content {
  margin:5%;
  margin-top:5%;
  width:90%;
  height:155%;
  border: 10;
  display: flex;
  flex-direction:column;
  justify-content: space-between;
}

.content-row{
  width:100%;
  height:14%;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  
}

.text{
  height:100%;
  border-radius: 10px;
  color: var(--text-colour);
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  background-color: var(--primary-colour);
  border:5px solid rgb(155, 96, 145);
  white-space: pre-line;
}

.image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: #3a0b3b;
  border:5px solid rgb(155, 96, 145);
}

.minecraftimage {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: #3a0b3b;
  border:5px solid rgb(155, 96, 145);
  overflow: hidden;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
}

h2 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 20px;
}

h3{
  margin-top: 0;
  font-size: 20px;
}
h4,
h5,
h6 {
  margin-top: 0;
  font-size: 12px;
}

/* .content-row:nth-child(1){
  background-color:#32a89d;
}
.content-row:nth-child(2){
  background-color:#49a682;
}
.content-row:nth-child(3){
  background-color:#6d49a6;
} */


a.one:link{
  color: var(--secondary-colour);
  text-decoration: none;
}

a.one:visited{
  color: var(--secondary-colour);
  text-decoration: none;
}

a.one:hover{
  color: var(--secondary-colour);
  text-decoration: none;
}

a.one:active{
  color: var(--secondary-colour);
  text-decoration: none;
}

a.two:link{
  color: var(--primary-colour);
  text-decoration: none;
}

a.two:visited{
  color: var(--primary-colour);
  text-decoration: none;
}

a.two:hover{
  color: var(--primary-colour);
  text-decoration: none;
}

a.two:active{
  color: var(--primary-colour);
  text-decoration: none;
}

.header {
  background-color: var(--primary-colour);
  color: var(--secondary-colour);
  height: 50px;
  padding: 10px;
}

.container {
  display: flex;
}

.left {
  margin: 0px;
  margin-right: auto;
}

.right {
  margin-right: 10px;
  float: right;
}

.midsection {
  margin-left: 25%;
  margin-top: 10%;
  margin-right: 22% ;
}

.midsectiontext {
  margin-left: 20px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}